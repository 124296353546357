import Amplify, { Auth, Hub } from 'aws-amplify';
import React, {
	FC,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
	Dispatch,
	SetStateAction,
} from 'react';
import { amplifyConfig } from '../amplifyConfig';
import { GatsbyPageProps } from '../declarations';

interface User {
	id?: string;
	username: string;
	attributes: {
		sub: string;
		email_verified: boolean;
		locale: string;
		given_name: string;
		email: string;
		'custom:company': string;
	};
}

export interface AuthContextStateProps {
	user?: User | null;
	authLoading: boolean;
	loggedIn: boolean;
	logout(): Promise<void>;
	setIsPreview: Dispatch<SetStateAction<boolean>>;
}

export const AuthContext = React.createContext<AuthContextStateProps>({
	loggedIn: false,
	user: null,
} as AuthContextStateProps);

export const useAuthContext = () => useContext(AuthContext);

Amplify.configure(amplifyConfig);

export const AuthContextProvider: FC = ({ children }) => {
	const [user, setUser] = useState<User | null>();
	const [isPreview, setIsPreview] = useState<boolean>(false);

	useEffect(() => {
		const updateUser = async () => {
			try {
				const user = await Auth.currentAuthenticatedUser({ bypassCache: false });

				setUser(user);
			} catch (err) {
				setUser(null);
			}
		};
		Hub.listen('auth', updateUser);
		updateUser();
		return () => Hub.remove('auth', updateUser);
	}, []);

	const logout = useCallback(async () => {
		return Auth.signOut();
	}, []);

	const value = useMemo((): AuthContextStateProps => {
		const data: AuthContextStateProps = {
			logout,
			user,
			loggedIn: !!user,
			setIsPreview,
			authLoading: user === undefined,
		};

		if (isPreview) {
			data.user = {
				id: 'preview',
				username: 'preview',
				attributes: {
					given_name: 'preview',
				} as any,
			};

			data.loggedIn = true;
			data.authLoading = false;
		}

		return data;
	}, [logout, user]);

	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const withAuthContextProvider = <P extends GatsbyPageProps>(
	Component: React.ComponentType<P>
): React.SFC<P> => {
	return props => (
		<AuthContextProvider>
			<Component {...props} />
		</AuthContextProvider>
	);
};
